<template >

  <base-card v-if="!readyToScan">
    <form @submit.prevent="submitData">
      <div class="form-control">
        <label for="title">PIN please</label>
      </div>
      <div class="form-control">
        <input v-model="enteredPin" id="pin" name="pin" type="text" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="4"/>
      </div>
      <p class="erpin" v-if="inputIsInvalid">Entered pin is incorect!</p>
      <div>
        <base-button type="submit">Start</base-button>
      </div>
    </form>
  </base-card>

  

  <div v-if="readyToScan && !validDecodeString">
    <p>{{ qrerror }}</p>
    <label>Scan ticket</label>
    <!-- <p class="textinfo">{{ decodedString }}</p> -->
    <div class="corners">
          <div class="top left"></div>
          <div class="top right"></div>
          <div class="bottom right"></div>
          <div class="bottom left"></div>
          <qrcode-stream  @init="onInit" @decode="onDecode"></qrcode-stream>
    </div>
    <div class="nodata" v-if="messageInvQr">
          <div >No data available, please scan next ticket</div>
    </div>

  </div>


  <div v-if="validDecodeString && resData != null">
    <div v-if="!isSendSms">
      <div class="ticketl">Ticket "{{ticket}}"</div>
      <table>
        <tr>
          <td class="red_text">First name</td>
          <td>{{resData.first_name}}</td>
        </tr>
        <tr>
          <td class="red_text">Last name</td>
          <td>{{resData.last_name}}</td>
        </tr>
        <tr>
          <td class="red_text">Company</td>
          <td>{{resData.company}}</td>
        </tr>
        <tr>
          <td class="red_text">Country</td>
          <td>{{resData.country}}</td>
        </tr>
        <tr>
          <td class="red_text">Zip Code</td>
          <td>{{resData.zip_code}}</td>
        </tr>
        <tr>
          <td class="red_text">Sales contact / ADM</td>
          <td>{{resData.sales_contact_id}}</td>
        </tr>
      </table>

      <div class="nodata" v-if="!resData.sales_contact_id">
          <div >No sales contact found, please scan next ticket</div>
      </div>
      <div v-else class = "buttons_ticket">
        <base-button @click="sendSms">Send text message</base-button>
        <base-button @click="sendSmsCall">Send SMS + Call({{ this.resData.phone }})</base-button>
      </div>
    </div>
    <div v-if="isSendSms">
      <label for="title">Text message has been sent</label>
    </div>
   
    <div class = "buttons_ticket">
        <base-button @click="nextTicket">Next ticket</base-button>
    </div>

    <!-- {{ resData }} -->
    <template v-if="resData.profiles.length">
      Coleagues: ( {{ resData.profiles.length }} ) - validate them.
        <template :key="profile.address.suid" v-for="profile in resData.profiles">
          <hr style="height:2px;border-width:0;color:gray;background-color:gray">
          <table class="coleague-table">
            <tbody>
              <tr>
                <td class="coleague-data"><span class="coleague-data">{{profile.user.fullname}}</span></td>
                <td>
                  <label class="container">Validate
                    <input :id="profile.address.suid" type="checkbox" name="validate" @change="onValidate($event, profile.address.suid)">
                    <span class="checkmark"></span>
                  </label>
                </td>
              </tr>
              <tr>
                <td class="coleague-email"><span class="coleague-data">{{profile.user.email}}</span></td>
              </tr>
              <tr>
                <td class="coleague-data"><span class="coleague-data">{{profile.address.country}}</span></td>
              </tr>
            </tbody>
          </table>
          
        </template>
      </template>
      
  </div>

</template>



<script>
//import { Console } from 'console';
import { ref } from 'vue'
import { QrcodeStream } from 'vue3-qrcode-reader'
export default {
  components:{
    QrcodeStream
  },
  // inject: ['addResource'],
  data() {
    return {
      qrerror : '',
      decodedString: '',
      enteredPin: '',
      inputIsInvalid: false,
      readyToScan:false,
      error: null,
      messageInvQr: false,
      validDecodeString: false,
      isSendSms: false,
      ticket: '',
      resData: null,
      code : '',
      name: '',
      VUE_APP_API : process.env.VUE_APP_API,
    };
  },
  methods: {
    async onInit(promise){
      try {
      const { capabilities } = await promise
      console.log(capabilities);
      // successfully initialized
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.qrerror = 'user denied camera access permisson';
        } else if (error.name === 'NotFoundError') {
          this.qrerror = 'no suitable camera device installed';
        } else if (error.name === 'NotSupportedError') {
          this.qrerror = 'page is not served over HTTPS (or localhost)';
        } else if (error.name === 'NotReadableError') {
          this.qrerror = 'maybe camera is already in use';
        } else if (error.name === 'OverconstrainedError') {
          this.qrerror = 'did you requested the front camera although there is none?';
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.qrerror = 'browser seems to be lacking features';
        }
        } finally { 
          // hide loading indicator
        }
    },

    nextTicket(){
      this.validDecodeString = false;
      this.readyToScan = true;
      this.isSendSms = false;
    },

    submitData() {
      fetch(this.VUE_APP_API + '/pin' , { method: 'POST',headers:{'Content-Type': 'application/json;charset=utf-8',},
        body: JSON.stringify({ 
          "pin": this.enteredPin
        }),
      }).then((response) => {
            if(response.ok){
              return response.json();
            }
            else{this.inputIsInvalid = true;}
        }).then((data) => {
          if(data)
          {
            this.code = data.code;
            this.name = data.name;
            this.readyToScan = true;
          }
        }).catch((error) => {
          console.log(error);
        });

    },

    confirmError() {
      this.inputIsInvalid = false;
    },

    sendSms(){
      fetch(this.VUE_APP_API + '/sms/send' , { method: 'POST',headers:{'Content-Type': 'application/json;charset=utf-8',},
        body: JSON.stringify({ 
          "lastName": this.resData.last_name,
          "first_name": this.resData.first_name,
          "company": this.resData.company, 
          "company_ort": this.resData.city, 
          "country": this.resData.country,
          "gender": this.resData.gender,
          "sales_id":this.resData.sales_contact_id,
          "location_code" : this.code,
          "ticket" : this.ticket 
        }),
        }).then((response) => {
            if(response.ok){
              return response;
            }    
        }).then((data) => {
          if(data)
          {this.isSendSms = true;}
        })
        // .catch((error) => {
        //   this.readyToScan = false;
        //   this.error = 'Failed to fetch data = please try again later!';
        //   console.log(error);
        // });
    },

    doCall(phone) {
      window.open('tel:' + phone);
    },

    onValidate(event, address_suid) {
      console.log('======= onValidate:', address_suid, this.ticket, event.target.checked, this.resData);
      if (event.target.checked) {
        fetch(this.VUE_APP_API + '/user/entry' , { method: 'POST',headers:{'Content-Type': 'application/json;charset=utf-8',},
          body: JSON.stringify({ 
            "ticket" : this.ticket,
            "user_id": address_suid,
            "location" : this.code,
        }),
        }).then((response) => {
            if(response.ok){
              // this.hasEntry();
              return response;
            }    
        })
      }
    },

    sendSmsCall(){
      fetch(this.VUE_APP_API + '/sms/send' , { method: 'POST',headers:{'Content-Type': 'application/json;charset=utf-8',},
        body: JSON.stringify({ 
          "lastName": this.resData.last_name,
          "first_name": this.resData.first_name,
          "company": this.resData.company, 
          "company_ort": this.resData.city, 
          "country": this.resData.country,
          "gender": this.resData.gender,
          "sales_id":this.resData.sales_contact_id,
          "location_code" : this.code,
          "ticket" : this.ticket
        }),
        }).then((response) => {
            if(response.ok){
              this.doCall(this.resData.phone);
              return response;
            }    
        }).then((data) => {
          if(data)
          {this.isSendSms = true;}
        })
        // .catch((error) => {
        //   this.readyToScan = false;
        //   this.error = 'Failed to fetch data = please try again later!';
        //   console.log(error);
        // });
    },

    onDecode(decodedString) {
      this.decodedString = decodedString;
      this.error = null;

      console.log("scanned QRCODE:", decodedString)

      fetch(this.VUE_APP_API + '/user' , {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ 
          ticket: this.decodedString,
          location : this.code,
        }),
        }).then((response) => {
          if(response.ok){
            return response.json();
          }
          else{this.messageInvQr = true;}
        }).then((data) => {
          if(data)
          {
            this.ticket = this.decodedString;
            this.messageInvQr = false;
            this.validDecodeString = true;
            this.resData = data;

            this.resData.profiles 
            && this.resData.profiles.length 
            && this.resData.profiles.forEach(profile => {
              profile.validate = false
              // console.log('======= profile:validate:', pThis, pThis.$set)
              profile.validate = ref(false)
            });
            console.log(data);
          }
        }).catch((error) => {
          this.readyToScan = false;
          this.error = 'Failed to fetch data = please try again later!';
          console.log(error);
        });
    }
  },
};
</script>

<style scoped>
label {
  font-weight: bold;
  font-size: 3.4em;
  display: block;
  margin-bottom: 3rem;
  text-align: center;
}

.ticketl {
  font-weight: bold;
  font-size: 2.4em;
  display: block;
  margin-bottom: 3rem;
  text-align: center;
}

input,
textarea {
  display: block;
  width: 100%;
  font: inherit;
  padding: 0.15rem;
  /* border: 1px solid #ccc; */
}

input:focus,
textarea:focus {
  outline: none;
  border-color: #3a0061;
  background-color: #f7ebff;
}

input,
number {
  width: 30%;
  margin-left: 35%;
  font-size: 2.4em;
  margin-bottom: 3.5rem;
  text-align: center;
  /* border: 1px solid #ccc; */
}

.form-control {
  margin: 1rem 0;
}

.erpin{
  color: red;
  font-size: 2.4em;
  text-align: center;
}

.qrborder{
  border: 9px solid #ccc; 
  padding: 1rem; 
  margin: 2rem auto;

  

  /* background-repeat: no-repeat;
  background-size: 5px 30px, 30px 5px; */
  
  max-width: 60rem; 
}

.textinfo{
  font-weight: bold;
  font-size: 1.4em;
  display: block;
  margin-bottom: 3.5rem;
  text-align: center;
}

.corners {
  position: relative;
  border: 9px solid #ccc; 
  padding: 1rem; 
  margin: 2rem auto;
  max-width: 40rem; 
}

.top, .bottom {
  position: absolute;
  width: 50px;
  height: 50px;
  pointer-events: none;
}

.top {
  top: 0;
  border-top: 6px solid;
}

.bottom {
  bottom: 0;
  border-bottom: 6px solid;
}

.left {
  left: 0;
  border-left: 6px solid;
}

.right {
  right: 0;
  border-right: 6px solid;
}
.nodata{
  background-color: red;
  color: rgb(255, 255, 255);
  font-size: 1.4em;
  text-align: center;
  margin: 2rem auto;
  max-width: 60rem; 
}

.buttons_ticket{
  text-align: center;
  font-size: 0.4em;
  margin: 2rem auto;
  max-width: 60rem; 
}


/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: solid #2196F3 1px;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.coleague-data {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* text-overflow: ellipsis " [..]"; */
  width: 250px;
  max-width: 250px;
}


.coleague-email {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* text-overflow: ellipsis " [..]"; */
  width: 250px;
  max-width: 250px;
}
@media (width > 480px) {
  .coleague-email {
    width: 400px;
    max-width: 400px;
  }
}
@media (width > 680px) {
  .coleague-email {
    width: 600px;
    max-width: 600px;
  }
}

.coleague-table {
  /* font-size: 1.2em; */
  margin: 1rem auto;
  max-width: 80rem; 
}


</style>